<script lang="ts" setup>
import queryString from 'query-string'
import { SearchIcon } from '@heroicons/vue/outline'
import PartsCategorySelect from '@components/atoms/inputs/PartsCategorySelect.vue'
import { onBeforeMount, reactive, toRefs } from 'vue'

type Props = {
  searchUrl?: string
}

type State = {
  keyword: string | null;
  carMakerId: number | null;
  carNameId: number | null;
  carModelId: number | null;
  categoryRootId: number | null;
  categoryParentId: number | null;
  categoryChildId: number | null;
  sortBy: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  searchUrl: '/parts/search'
})
const { searchUrl } = toRefs(props)
const state = reactive<State>({
  keyword: null,
  carMakerId: null,
  carNameId: null,
  carModelId: null,
  categoryRootId: null,
  categoryParentId: null,
  categoryChildId: null,
  sortBy: null
})

const convertStateToQueryKey = (key: string): string => {
  switch (key) {
    case 'keyword':
      return 'search-text'
    case 'carMakerId':
      return 'makers_id'
    case 'carNameId':
      return 'car_names_id'
    case 'carModelId':
      return 'car_models_id'
    case 'categoryRootId':
      return 'parts-detail-1'
    case 'categoryParentId':
      return 'parts-detail-2'
    case 'categoryChildId':
      return 'parts-detail-3'
    case 'sortBy':
      return 'parts-sort-by'
    default:
      return ''
  }
}

const onSubmit = () => {
  let queryParams: { [k: string]: string | number } = {}
  Object.entries(state).forEach(([key, value]) => {
    if (!value) { return }
    queryParams[convertStateToQueryKey(key)] = value
  })
  const searchParams = queryString.stringify(queryParams)
  window.location.href = (searchParams === '') ? searchUrl.value : `${searchUrl.value}?${searchParams}`
}

onBeforeMount(() => {
  const currentUrl = window.location.origin + window.location.pathname
  if (currentUrl === searchUrl.value) {
    Object.entries(queryString.parse(window.location.search)).forEach(([key, value]) => {
      switch (key) {
        case 'search-text':
          state.keyword = value as string
          break
        case 'makers_id':
          state.carMakerId = Number(value)
          break
        case 'car_names_id':
          state.carNameId = Number(value)
          break
        case 'car_models_id':
          state.carModelId = Number(value)
          break
        case 'parts-detail-1':
          state.categoryRootId = Number(value)
          break
        case 'parts-detail-2':
          state.categoryParentId = Number(value)
          break
        case 'parts-detail-3':
          state.categoryChildId = Number(value)
          break
        case 'parts-sort-by':
          state.sortBy = Number(value)
          break
      }
    })
  }
})

</script>

<template lang="pug">
form.search-bar.flex.border.border-gray.rounded.w-full(
  :action="searchUrl"
  method="GET"
  @submit.prevent="onSubmit"
)
  .search-bar__category
    parts-category-select.search-bar__category-select(
      is-root
      v-model:id="state.categoryRootId"
    )
  .search-bar__keyword
    input.search-bar__keyword-input(
      placeholder="車種名、ブランド等で検索"
      type="search"
      v-model="state.keyword"
    )
    button.search-bar__button(aria-label="検索")
      search-icon.search-bar__button-icon
</template>
