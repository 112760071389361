const ButtonActions = {
  Cancel: 'CANCEL',
  Close: 'CLOSE',
  Link: 'LINK',
  Ok: 'OK',
  Reload: 'RELOAD'
} as const

export type ButtonAction = typeof ButtonActions[keyof typeof ButtonActions]
export default ButtonActions
