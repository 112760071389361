import type { AspidaClient } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods0 } from './car-models'
import type { Methods as Methods1 } from './car-names'
import type { Methods as Methods2 } from './cars'
import type { Methods as Methods3 } from './cart'
import type { Methods as Methods4 } from './cart/_id'
import type { Methods as Methods5 } from './inquiries/products'
import type { Methods as Methods6 } from './makers'
import type { Methods as Methods7 } from './me/favorites/parts'
import type { Methods as Methods8 } from './me/favorites/parts/_id'
import type { Methods as Methods9 } from './parts'
import type { Methods as Methods10 } from './parts-categories'
import type { Methods as Methods11 } from './products/inquiries'
import type { Methods as Methods12 } from './products/purchase-inquiries'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/car-models'
  const PATH1 = '/car-names'
  const PATH2 = '/cars'
  const PATH3 = '/cart'
  const PATH4 = '/inquiries/products'
  const PATH5 = '/makers'
  const PATH6 = '/me/favorites/parts'
  const PATH7 = '/parts'
  const PATH8 = '/parts-categories'
  const PATH9 = '/products/inquiries'
  const PATH10 = '/products/purchase-inquiries'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    car_models: {
      get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
        fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
      $get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
        fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | undefined) =>
        `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    car_names: {
      get: (option: { query: Methods1['get']['query'], config?: T | undefined }) =>
        fetch<Methods1['get']['resBody']>(prefix, PATH1, GET, option).json(),
      $get: (option: { query: Methods1['get']['query'], config?: T | undefined }) =>
        fetch<Methods1['get']['resBody']>(prefix, PATH1, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods1['get']['query'] } | undefined) =>
        `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    cars: {
      get: (option: { query: Methods2['get']['query'], config?: T | undefined }) =>
        fetch<Methods2['get']['resBody']>(prefix, PATH2, GET, option).json(),
      $get: (option: { query: Methods2['get']['query'], config?: T | undefined }) =>
        fetch<Methods2['get']['resBody']>(prefix, PATH2, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods2['get']['query'] } | undefined) =>
        `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    cart: {
      _id: (val1: number | string) => {
        const prefix1 = `${PATH3}/${val1}`

        return {
          put: (option: { body: Methods4['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods4['put']['resBody']>(prefix, prefix1, PUT, option).json(),
          $put: (option: { body: Methods4['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods4['put']['resBody']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          delete: (option: { body: Methods4['delete']['reqBody'], config?: T | undefined }) =>
            fetch<Methods4['delete']['resBody']>(prefix, prefix1, DELETE, option).json(),
          $delete: (option: { body: Methods4['delete']['reqBody'], config?: T | undefined }) =>
            fetch<Methods4['delete']['resBody']>(prefix, prefix1, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      get: (option?: { query?: Methods3['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json(),
      $get: (option?: { query?: Methods3['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json().then(r => r.body),
      post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json(),
      $post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods3['get']['query'] } | undefined) =>
        `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    inquiries: {
      products: {
        post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods5['post']['resBody']>(prefix, PATH4, POST, option).json(),
        $post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods5['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH4}`
      }
    },
    makers: {
      get: (option?: { query?: Methods6['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods6['get']['resBody']>(prefix, PATH5, GET, option).json(),
      $get: (option?: { query?: Methods6['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods6['get']['resBody']>(prefix, PATH5, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods6['get']['query'] } | undefined) =>
        `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    me: {
      favorites: {
        parts: {
          _id: (val3: number | string) => {
            const prefix3 = `${PATH6}/${val3}`

            return {
              get: (option?: { query?: Methods8['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods8['get']['resBody']>(prefix, prefix3, GET, option).json(),
              $get: (option?: { query?: Methods8['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods8['get']['resBody']>(prefix, prefix3, GET, option).json().then(r => r.body),
              put: (option?: { body?: Methods8['put']['reqBody'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods8['put']['resBody']>(prefix, prefix3, PUT, option).json(),
              $put: (option?: { body?: Methods8['put']['reqBody'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods8['put']['resBody']>(prefix, prefix3, PUT, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods8['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          },
          get: (option?: { query?: Methods7['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH6, GET, option).json(),
          $get: (option?: { query?: Methods7['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods7['get']['query'] } | undefined) =>
            `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        }
      }
    },
    parts: {
      get: (option?: { query?: Methods9['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods9['get']['resBody']>(prefix, PATH7, GET, option).json(),
      $get: (option?: { query?: Methods9['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods9['get']['resBody']>(prefix, PATH7, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods9['get']['query'] } | undefined) =>
        `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    parts_categories: {
      get: (option?: { query?: Methods10['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods10['get']['resBody']>(prefix, PATH8, GET, option).json(),
      $get: (option?: { query?: Methods10['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods10['get']['resBody']>(prefix, PATH8, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods10['get']['query'] } | undefined) =>
        `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    products: {
      inquiries: {
        post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods11['post']['resBody']>(prefix, PATH9, POST, option).json(),
        $post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods11['post']['resBody']>(prefix, PATH9, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH9}`
      },
      purchase_inquiries: {
        post: (option: { body: Methods12['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods12['post']['resBody']>(prefix, PATH10, POST, option).json(),
        $post: (option: { body: Methods12['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods12['post']['resBody']>(prefix, PATH10, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH10}`
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
