import { Http } from '@status/codes'
import { AxiosError } from 'axios'
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import ButtonActions from '@enums/button-action'
import DialogTypes from '@enums/dialog-type'
import { State as RootState } from '@stores/index'
import { ShowPayload as DialogPayload } from '@stores/modules/dialog'

interface State {
  error: AxiosError | null,
  dialog: DialogPayload | null
  message: NullableString,
  validationErrors: any
}

const state = (): State => ({
  error: null,
  dialog: null,
  message: null,
  validationErrors: null
})

const getters: GetterTree<State, RootState> = {
  error: state => state.error,
  dialog: state => state.dialog,
  message: state => state.message,
  validationErrors: state => state.validationErrors
}

const actions: ActionTree<State, RootState> = {
  handle: async ({ commit, dispatch }, error: AxiosError) => {
    commit('setError', error)
    const { code, config, request, response, status } = error
    if (response) {
      const { data, status } = response
      switch (status) {
        case Http.Unauthorized:
          await dispatch('unauthorized')
          break
        case 419:
          await dispatch('csrfTokenMismatch')
          break
        case Http.UnprocessableEntity:
          await dispatch('unprocessableEntity', data)
          break
        case Http.TooManyRequests:
          await dispatch('tooManyRequest')
          break
        default:
          await dispatch('internalServerError', error)
          break
      }
    } else {
      switch (code) {
        case AxiosError.ECONNABORTED:
          await dispatch('timeout')
          break
        default:
          break
      }
    }
  },
  timeout: ({ commit }) => {
    const message = 'リクエストがタイムアウトしました。接続環境をお確かめの上、再度お試しください。'
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Alert,
      body: message,
      buttons: [{ action: ButtonActions.Close, label: '閉じる' }]
    })
  },
  unauthorized: ({ commit }) => {
    const message = 'この操作を行うにはログインが必要です。'
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Confirm,
      body: message,
      buttons: [
        { action: ButtonActions.Cancel, label: 'キャンセル' },
        { action: ButtonActions.Link, href: '/user/login', label: 'ログインする' },
      ]
    })
  },
  csrfTokenMismatch: ({ commit }) => {
    const message = "ページの有効期限が切れています。\nページの再読み込みが必要です。"
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Alert,
      body: message,
      buttons: [
        { action: ButtonActions.Reload, label: '再読み込み' }
      ]
    })
  },
  unprocessableEntity: ({ commit }, { errors, message }) => {
    commit ('setMessage', message ?? '入力内容をご確認ください')
    commit('setValidationErrors', errors)
  },
  tooManyRequest: ({ commit }) => {
    const message = 'リクエスト回数が多すぎます。時間をあけて再度お試しください。'
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Alert,
      body: message,
      buttons: [{ action: ButtonActions.Close, label: '閉じる' }]
    })
  },
  internalServerError: ({ commit }, payload: AxiosError) => {
    const message = "サーバー側で予期せぬエラーが発生しました。\n同じ症状が多発する場合は運営にお問合せください。"
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Alert,
      body: message,
      buttons: [{ action: ButtonActions.Close, label: '閉じる' }]
    })
  }
}

const mutations: MutationTree<State> = {
  setError: (state, payload) => state.error = payload,
  setDialog: (state, payload) => state.dialog = payload,
  setMessage: (state, payload) => state.message = payload,
  setValidationErrors: (state, payload) => state.validationErrors = payload
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
