import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import crud from '@stores/modules/crud'
import Repository from '@repositories/parts-category'

type FetchPayload = { parentId: number }

interface State {
  stackedCollection: {
    parentId: number
    collection: PartsCategory.Collection
  }[] | null
}

const state = (): State => ({
  stackedCollection: null
})

const getters: GetterTree<State, RootState> = {
  stackedCollection: state => state.stackedCollection,
  isStacked: state => (id: number) => Boolean(state.stackedCollection?.map(v => v.parentId).includes(id)),
  findStackedBy: state => (id: number) => state.stackedCollection?.find(v => v.parentId === id) || null
}

const actions: ActionTree<State, RootState> = {
  fetch: async ({ commit, dispatch, getters, state }, payload?: FetchPayload) => {
    await dispatch('crud/error/outputTypeLog')
    await dispatch('crud/fetch', {
      method: Repository.fetch,
      payload: payload?.parentId
    })
    if (getters['crud/isSucceedFetch']) {
      commit('setStackedCollection', {
        parentId: payload?.parentId || 0,
        collection: getters['crud/collection']
      })
    }
  }
}

const mutations: MutationTree<State> = {
  setStackedCollection: (state, payload) => {
    if (state.stackedCollection === null) {
      state.stackedCollection = [payload]
    } else if (state.stackedCollection.map(v => v.parentId).includes(payload.parentId)) {
      return
    } else {
      state.stackedCollection.push(payload)
    }
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    crud
  }
}

export default module
