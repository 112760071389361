import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import DialogTypes, { DialogType } from '@enums/dialog-type'
import ButtonActions, { ButtonAction } from '@enums/button-action'

type DialogButton = {
  action: ButtonAction
  label: string
  href?: string
}

export type ShowPayload = {
  body: string
  type: DialogType
  buttons: DialogButton[]
}

interface State {
  isShown: boolean
  body: NullableString
  buttons: DialogButton[]
  type: DialogType | null
}

const DEFAULT_BUTTONS: DialogButton[] = [
  {
    action: ButtonActions.Ok,
    label: 'OK'
  }
]

const state = (): State => ({
  isShown: false,
  body: null,
  buttons: DEFAULT_BUTTONS,
  type: DialogTypes.Alert
})

const getters: GetterTree<State, RootState> = {
  body: state => state.body,
  buttons: state => state.buttons,
  isShown: state => state.isShown,
  type: state => state.type
}

const actions: ActionTree<State, RootState> = {
  show: ({ commit }, { body, buttons, type }: ShowPayload) => {
    commit('setBody', body)
    commit('setButtons', buttons)
    commit('setType', type)
    commit('setIsShown', true)
  },
  hide: ({ commit }) => {
    commit('setIsShown', false)
    commit('setBody', null)
    commit('setButtons', DEFAULT_BUTTONS)
    commit('setType', null)
  }
}

const mutations: MutationTree<State> = {
  setBody: (state, payload) => state.body = payload,
  setButtons: (state, payload) => state.buttons = payload,
  setIsShown: (state, payload) => state.isShown = payload,
  setType: (state, payload) => state.type = payload
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default module
