import axios, { AxiosError } from 'axios'
import aspida from '@aspida/axios'
import api from '@api/endpoints/$api'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { snakeCase } from 'change-case'
import { Http } from '@status/codes'

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withCredentials: true
})

export const ApiClient = api(aspida(axiosInstance, { baseURL: '/api' }))

export const request = async (
  requestMethod: any, payload?: any, isGetRequest: boolean = false, config?: any
): Promise<any> => {
  try {
    const result = requestMethod(payloadToSnakecase(payload, isGetRequest, config))
    const response = (result instanceof Promise) ? await result : result
    return responseToCamelCase(response)
  } catch (error: any) {
    if (error?.isAxiosError) {
      return responseToCamelCase(handleAxiosError(error))
    } else {
      return responseToCamelCase(handleUnexpectedError(error))
    }
  }
}

const handleAxiosError = (error: AxiosError<any>) => {
  if (error?.code === 'ECONNABORTED') {
    return { errors: {}, hasError: true, message: 'リクエストがタイムアウトしました'  }
  }

  if (error?.response?.status === Http.UnprocessableEntity) {
    const { errors, message } = error?.response?.data
    return { errors, hasError: true, message }
  }

  return { errors: {}, hasError: true, message: error.message }
}

const handleUnexpectedError = (error: Error) => {
  return { errors: {}, hasError: true, message: error.message }
}

export const payloadToSnakecase = <Payload>(payload?: Payload, isGetRequest: boolean = false, config?: any): any => {
  if (payload === undefined) {
    return undefined
  }

  const snakedPayload = snakecaseKeys(payload, { deep: true })

  if (isGetRequest) {
    return { query: snakedPayload, config }
  }

  // @todo: File形式をPOSTするときにここ調整しよう
  // Object.entries(payload).forEach(([key, value]) => {
  //   if (Array.isArray(value) && value.filter(v => v instanceof File).length > 0) {
  //     value.forEach((file, index) => {
  //       const newKey = snakeCase(key) + `[${index}]`
  //       snakedPayload[snakeCase(key) + `[${index}]`] = file
  //     })
  //     delete snakedPayload[snakeCase(key)]
  //   } else if (value instanceof File) {
  //     snakedPayload[snakeCase(key)] = value
  //   }
  // })

  return { body: snakedPayload, config }
}

export const responseToCamelCase = <Response>(response: Response) => {
  return camelcaseKeys<Response>(response, { deep: true })
}
