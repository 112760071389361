import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import error from '@stores/modules/error'
import status from '@stores/modules/crud/status'
import { AspidaResponse } from 'aspida'

type ActionPayload = {
  method: (payload?: any) => Promise<AspidaResponse>
  payload?: any
}

interface State {
  collection: any
  paginate: LaravelPaginate<any> | null
  resource: any
  response: AspidaResponse | null
}

const state = (): State => ({
  collection: null,
  paginate: null,
  resource: null,
  response: null
})

const getters: GetterTree<State, RootState> = {
  collection: state => state.collection,
  paginate: state => state.paginate,
  resource: state => state.resource,
  response: state => state.response
}

const actions: ActionTree<State, RootState> = {
  clearCollection: async ({ commit }) => {
    commit('setCollection', null)
  },
  fetch: async ({ commit, dispatch, getters }, { method, payload }: ActionPayload) => {
    await dispatch('runningFetch')
    try {
      const response: AspidaResponse<any> = await method(payload)
      commit('setResponse', response)
      if (response.body?.data && response.body?.meta) {
        if (getters.collection === null) {
          commit('setCollection', response.body.data)
        } else {
          commit('setCollection', getters.collection.concat(response.body.data))
        }
        commit('setPaginate', response.body)
      } else {
        commit('setCollection', response.body)
      }
      await dispatch('successFetch')
    } catch (error) {
      commit('setCollection', null)
      commit('setPaginate', null)
      await dispatch('error/handle', error)
      await dispatch('failureFetch')
    }
  },
  find: async ({ commit, dispatch }, { method, payload }: ActionPayload) => {
    await dispatch('runningFind')
    try {
      const response = await method(payload)
      commit('setResponse', response)
      commit('setResource', response.body)
      await dispatch('successFind')
    } catch (error) {
      commit('setResource', null)
      await dispatch('error/handle', error)
      await dispatch('failureFind')
    }
  },
  create: async ({ commit, dispatch }, { method, payload }) => {
    await dispatch('runningCreate')
    try {
      const response = await method(payload)
      commit('setResponse', response)
      commit('setResource', response.body)
      await dispatch('successCreate')
    } catch (error) {
      commit('setResource', null)
      await dispatch('error/handle', error)
      await dispatch('failureCreate')
    }
  },
  update: async ({ commit, dispatch }, { method, payload }) => {
    await dispatch('runningUpdate')
    try {
      const response = await method(payload)
      commit('setResponse', response)
      commit('setResource', response.body)
      await dispatch('successUpdate')
    } catch (error) {
      commit('setResource', null)
      await dispatch('error/handle', error)
      await dispatch('failureUpdate')
    }
  },
  delete: async ({ commit, dispatch }, { method, payload }) => {
    await dispatch('runningDelete')
    try {
      const response = await method(payload)
      commit('setResponse', response)
      commit('setResource', response.body)
      await dispatch('successDelete')
    } catch (error) {
      commit('setResource', null)
      await dispatch('error/handle', error)
      await dispatch('failureDelete')
    }
  }
}

const mutations: MutationTree<State> = {
  setCollection: (state, payload) => state.collection = payload,
  setPaginate: (state, payload) => state.paginate = payload,
  setResource: (state, payload) => state.resource = payload,
  setResponse: (state, payload) => state.response = payload
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    error,
    status
  }
}

export default module
