import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import ProcessStates, { ProcessState } from '@enums/process-state'

interface State {
  fetch: ProcessState
  find: ProcessState
  create: ProcessState
  update: ProcessState
  delete: ProcessState
  search: ProcessState
  upload: ProcessState
  download: ProcessState
}

const state = (): State => ({
  fetch: ProcessStates.Initial,
  find: ProcessStates.Initial,
  create: ProcessStates.Initial,
  update: ProcessStates.Initial,
  delete: ProcessStates.Initial,
  search: ProcessStates.Initial,
  upload: ProcessStates.Initial,
  download: ProcessStates.Initial
})

const getters: GetterTree<State, RootState> = {
  isInitialFetch: state => state.fetch === ProcessStates.Initial,
  isInitialFind: state => state.find === ProcessStates.Initial,
  isInitialCreate: state => state.create === ProcessStates.Initial,
  isInitialUpdate: state => state.update === ProcessStates.Initial,
  isInitialDelete: state => state.delete === ProcessStates.Initial,
  isInitialSearch: state => state.search === ProcessStates.Initial,
  isInitialUpload: state => state.upload === ProcessStates.Initial,
  isInitialDownload: state => state.download === ProcessStates.Initial,
  isRunningFetch: state => state.fetch === ProcessStates.Running,
  isRunningFind: state => state.find === ProcessStates.Running,
  isRunningCreate: state => state.create === ProcessStates.Running,
  isRunningUpdate: state => state.update === ProcessStates.Running,
  isRunningDelete: state => state.delete === ProcessStates.Running,
  isRunningSearch: state => state.search === ProcessStates.Running,
  isRunningUpload: state => state.upload === ProcessStates.Running,
  isRunningDownload: state => state.download === ProcessStates.Running,
  isPendingFetch: state => state.fetch === ProcessStates.Pending,
  isPendingFind: state => state.find === ProcessStates.Pending,
  isPendingCreate: state => state.create === ProcessStates.Pending,
  isPendingUpdate: state => state.update === ProcessStates.Pending,
  isPendingDelete: state => state.delete === ProcessStates.Pending,
  isPendingSearch: state => state.search === ProcessStates.Pending,
  isPendingUpload: state => state.upload === ProcessStates.Pending,
  isPendingDownload: state => state.download === ProcessStates.Pending,
  isSucceedFetch: state => state.fetch === ProcessStates.Success,
  isSucceedFind: state => state.find === ProcessStates.Success,
  isSucceedCreate: state => state.create === ProcessStates.Success,
  isSucceedUpdate: state => state.update === ProcessStates.Success,
  isSucceedDelete: state => state.delete === ProcessStates.Success,
  isSucceedSearch: state => state.search === ProcessStates.Success,
  isSucceedUpload: state => state.upload === ProcessStates.Success,
  isSucceedDownload: state => state.download === ProcessStates.Success,
  isFailedFetch: state => state.fetch === ProcessStates.Failure,
  isFailedFind: state => state.find === ProcessStates.Failure,
  isFailedCreate: state => state.create === ProcessStates.Failure,
  isFailedUpdate: state => state.update === ProcessStates.Failure,
  isFailedDelete: state => state.delete === ProcessStates.Failure,
  isFailedSearch: state => state.search === ProcessStates.Failure,
  isFailedUpload: state => state.upload === ProcessStates.Failure,
  isFailedDownload: state => state.download === ProcessStates.Failure
}

const actions: ActionTree<State, RootState> = {
  initialFetch: ({ commit }) => commit('setFetch', ProcessStates.Initial),
  runningFetch: ({ commit }) => commit('setFetch', ProcessStates.Running),
  pendingFetch: ({ commit }) => commit('setFetch', ProcessStates.Pending),
  successFetch: ({ commit }) => commit('setFetch', ProcessStates.Success),
  failureFetch: ({ commit }) => commit('setFetch', ProcessStates.Failure),
  initialFind: ({ commit }) => commit('setFind', ProcessStates.Initial),
  runningFind: ({ commit }) => commit('setFind', ProcessStates.Running),
  pendingFind: ({ commit }) => commit('setFind', ProcessStates.Pending),
  successFind: ({ commit }) => commit('setFind', ProcessStates.Success),
  failureFind: ({ commit }) => commit('setFind', ProcessStates.Failure),
  initialCreate: ({ commit }) => commit('setCreate', ProcessStates.Initial),
  runningCreate: ({ commit }) => commit('setCreate', ProcessStates.Running),
  pendingCreate: ({ commit }) => commit('setCreate', ProcessStates.Pending),
  successCreate: ({ commit }) => commit('setCreate', ProcessStates.Success),
  failureCreate: ({ commit }) => commit('setCreate', ProcessStates.Failure),
  initialUpdate: ({ commit }) => commit('setUpdate', ProcessStates.Initial),
  runningUpdate: ({ commit }) => commit('setUpdate', ProcessStates.Running),
  pendingUpdate: ({ commit }) => commit('setUpdate', ProcessStates.Pending),
  successUpdate: ({ commit }) => commit('setUpdate', ProcessStates.Success),
  failureUpdate: ({ commit }) => commit('setUpdate', ProcessStates.Failure),
  initialDelete: ({ commit }) => commit('setDelete', ProcessStates.Initial),
  runningDelete: ({ commit }) => commit('setDelete', ProcessStates.Running),
  pendingDelete: ({ commit }) => commit('setDelete', ProcessStates.Pending),
  successDelete: ({ commit }) => commit('setDelete', ProcessStates.Success),
  failureDelete: ({ commit }) => commit('setDelete', ProcessStates.Failure),
  initialSearch: ({ commit }) => commit('setSearch', ProcessStates.Initial),
  runningSearch: ({ commit }) => commit('setSearch', ProcessStates.Running),
  pendingSearch: ({ commit }) => commit('setSearch', ProcessStates.Pending),
  successSearch: ({ commit }) => commit('setSearch', ProcessStates.Success),
  failureSearch: ({ commit }) => commit('setSearch', ProcessStates.Failure),
  initialUpload: ({ commit }) => commit('setUpload', ProcessStates.Initial),
  runningUpload: ({ commit }) => commit('setUpload', ProcessStates.Running),
  pendingUpload: ({ commit }) => commit('setUpload', ProcessStates.Pending),
  successUpload: ({ commit }) => commit('setUpload', ProcessStates.Success),
  failureUpload: ({ commit }) => commit('setUpload', ProcessStates.Failure),
  initialDownload: ({ commit }) => commit('setDownload', ProcessStates.Initial),
  runningDownload: ({ commit }) => commit('setDownload', ProcessStates.Running),
  pendingDownload: ({ commit }) => commit('setDownload', ProcessStates.Pending),
  successDownload: ({ commit }) => commit('setDownload', ProcessStates.Success),
  failureDownload: ({ commit }) => commit('setDownload', ProcessStates.Failure)
}

const mutations: MutationTree<State> = {
  setFetch: (state, payload: ProcessState) => state.fetch = payload,
  setFind: (state, payload: ProcessState) => state.find = payload,
  setCreate: (state, payload: ProcessState) => state.create = payload,
  setUpdate: (state, payload: ProcessState) => state.update = payload,
  setDelete: (state, payload: ProcessState) => state.delete = payload,
  setSearch: (state, payload: ProcessState) => state.search = payload,
  setUpload: (state, payload: ProcessState) => state.upload = payload,
  setDownload: (state, payload: ProcessState) => state.download = payload
}

const module: Module<State, RootState> = {
  state,
  getters,
  actions,
  mutations
}

export default module
