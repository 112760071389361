import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import crud from '@stores/modules/crud'
import Repository from '@repositories/product/inquiry'

interface State {}

const state = (): State => ({})

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  create: async ({ dispatch, getters }, payload: Product.Inquiry.Request.Create) => {
    await dispatch('crud/error/outputTypeDialog')
    await dispatch('crud/create', {
      method: Repository.create,
      payload: payload
    })
  },
}

const mutations: MutationTree<State> = {}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    crud
  }
}

export default module
