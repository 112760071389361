import { GetterTree, ActionTree, MutationTree } from 'vuex'
import inquiry from '@stores/modules/product/inquiry'
import purchaseInquiry from '@stores/modules/product/purchase-inquiry'
import { State as RootState } from '@stores/index'

interface State {}

const state = (): State => ({})

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {}

const mutations: MutationTree<State> = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    inquiry,
    purchaseInquiry
  }
}
