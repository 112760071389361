import animateScrollTo from 'animated-scroll-to'

import { onLoaded } from '@libs/on-loaded'

onLoaded(() => {
  const els = document.querySelectorAll('.js-scroll-to')
  els.forEach((el) => {
    el.addEventListener('click', async (e) => {
      e.preventDefault()
      const currentTarget = e?.currentTarget
      if(!(currentTarget instanceof HTMLElement)) {
        return
      }
      const target = document.querySelector(currentTarget.dataset.scrollTo as string)
      await animateScrollTo(
        target as Element,
        {
          verticalOffset: -120 // header分のマージン
        }
      )
    })
  })
})
