import { ActionTree, createStore, GetterTree, ModuleTree, MutationTree, Store, useStore as injectUseStore } from 'vuex'
import modules from './modules'
import { InjectionKey } from 'vue'

export interface State {
  user: any
}

const state = (): State => ({
  user: null
})

const getters: GetterTree<State, State> = {
  isLogin: state => state.user !== null,
  user: state => state.user
}

const actions: ActionTree<State, State> = {
  setUser: ({ commit }, payload) => {
    commit('setUser', payload)
  }
}

const mutations: MutationTree<State> = {
  setUser: (state, payload) => state.user = payload
}

export const key: InjectionKey<Store<State>> = Symbol()
export const useStore = () => injectUseStore(key)
export const store = createStore({
  state,
  getters,
  actions,
  mutations,
  modules
})
