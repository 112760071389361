const setVh = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const setVw = () => {
  let vw = window.innerWidth * 0.01
  document.documentElement.style.setProperty('--vw', `${vw}px`)
}

const onResize = () => {
  setVh()
  setVw()
}

const onDomContentLoaded = () => {
  setVh()
  setVw()
}

window.addEventListener('DOMContentLoaded', onDomContentLoaded)
window.addEventListener('resize', onResize)
