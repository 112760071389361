import { App, Component } from 'vue'
import { ExclamationCircleIcon, HomeIcon, LoginIcon, SearchIcon, ShoppingCartIcon, UserIcon } from '@heroicons/vue/outline'

export const bindBaseComponents = async (app: App) => {
  await bindComponents(app, import.meta.glob('../components/atoms/app/**/*.vue'))
  await bindComponents(app, import.meta.glob('../components/molecules/app/**/*.vue'))
  await bindComponents(app, import.meta.glob('../components/organisms/app/**/*.vue'))
  await app.component('exclamation-icon', ExclamationCircleIcon)
  await app.component('home-icon', HomeIcon)
  await app.component('shopping-cart-icon', ShoppingCartIcon)
  await app.component('login-icon', LoginIcon)
  await app.component('search-icon', SearchIcon)
  await app.component('user-icon', UserIcon)
}

export const bindComponents = async (app: App, components: GlobImportResult) => {
  const boundComponentNames = Object.keys(app._context.components)
  return Promise.all(
    Object.entries(components).map(async ([key, value]) => {
      if (!/.vue$/.test(key)) return

      const name: string = (key.split('/').pop() || '').replace('.vue', '')
      if (boundComponentNames.includes(name)) return

      const component: Component = (await value()).default

      app.component(name, component)
    })
  )
}
