<script lang="ts" setup>
import { computed, onBeforeMount, ref, toRefs, watch } from 'vue'
import { useStore } from '@stores/index'

type Props = {
  isRoot?: boolean;
  id?: number | null;
  parentId?: number | null;
}

const store = useStore()
const props = withDefaults(defineProps<Props>(), {
  isRoot: false,
  id: null,
  parentId: null
})
const emits = defineEmits(['update:id'])
const { isRoot, id, parentId } = toRefs(props)
const items = ref<any>([])
const isDisabled = computed<boolean>(() => items.value.length === 0)
const computedId = computed({
  get: () => id.value,
  set: (value) => {
    emits('update:id', value)
  }
})

const fetchRoots = async () => {
  if (store.getters['partsCategory/isStacked'](0)) {
    items.value = store.getters['partsCategory/findStackedBy'](0).collection
  } else {
    await store.dispatch('partsCategory/fetch')
    items.value = store.getters['partsCategory/crud/collection']
  }
}

const fetchChildren = async (parentId: number) => {
  if (store.getters['partsCategory/isStacked'](parentId)) {
    items.value = store.getters['partsCategory/findStackedBy'](parentId).collection
  } else {
    await store.dispatch('partsCategory/fetch', { parentId })
    items.value = store.getters['partsCategory/crud/collection']
  }
}

onBeforeMount(async () => {
  if (isRoot.value) {
    setTimeout(async () => {
      await fetchRoots()
    }, 300)
  }

  if (parentId.value) {
    await fetchChildren(parentId.value)
  }
})

watch(parentId, async (newValue, _oldValue) => {
  computedId.value = null
  items.value = []
  if (newValue !== null) {
    await fetchChildren(newValue)
  }
})
</script>

<template lang="pug">
select.form-input.parts-category-select(
  v-model="computedId"
  :disabled="isDisabled"
)
  option(:value="null")
    | {{ isDisabled ? '未選択' : 'すべて' }}
  option(
    v-for="(item, index) in items"
    :key="index"
    :value="item.id"
    :selected="id === item.id"
  )
    | {{ item.name }}
</template>
