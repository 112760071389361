import { AxiosError } from 'axios'
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import { ShowPayload as DialogPayload } from '@stores/modules/dialog'
import axios from '@stores/modules/error/axios'
import ButtonActions from '@enums/button-action'
import DialogTypes from '@enums/dialog-type'
import ErrorOutputTypes, { ErrorOutputType } from '@enums/error-output-type'

type HandleError = Error | AxiosError

interface State {
  error: HandleError | null
  dialog: DialogPayload | null
  message: NullableString
  outputType: ErrorOutputType
}

const state = (): State => ({
  error: null,
  dialog: null,
  message: null,
  outputType: ErrorOutputTypes.None
})

const getters: GetterTree<State, RootState> = {
  error: state => state.error,
  dialog: state => state.dialog,
  message: state => state.message,
  outputType: state => state.outputType
}

const actions: ActionTree<State, RootState> = {
  outputTypeNone: ({ commit }) => commit('setOutputType', ErrorOutputTypes.None),
  outputTypeLog: ({ commit }) => commit('setOutputType', ErrorOutputTypes.Log),
  outputTypeDialog: ({ commit }) => commit('setOutputType', ErrorOutputTypes.Dialog),
  outputTypeToast: ({ commit }) => commit('setOutputType', ErrorOutputTypes.Toast),
  handle: async ({ commit, dispatch, getters }, error: HandleError) => {
    if (error instanceof AxiosError) {
      await dispatch('axios/handle', error)
      commit('setDialog', getters['axios/dialog'])
    } else {
      await dispatch('unexpectedError', error)
    }
    switch (getters.outputType) {
      case ErrorOutputTypes.None:
        break
      case ErrorOutputTypes.Log:
        console.log(error.name, error)
        break
      case ErrorOutputTypes.Dialog:
        await dispatch('dialog/show', getters.dialog, { root: true })
        break
      case ErrorOutputTypes.Toast:
        // @todo
        break
      default:
        console.error(error)
    }
  },
  unexpectedError: async ({ commit, dispatch }, error: HandleError) => {
    const message = `予期せぬエラーが発生しました。\n${error.message}`
    commit('setMessage', message)
    commit('setDialog', {
      type: DialogTypes.Alert,
      body: message,
      buttons: [{ action: ButtonActions.Close, label: '閉じる' }]
    })
  },
}

const mutations: MutationTree<State> = {
  setError: (state, payload) => state.error = payload,
  setDialog: (state, payload) => state.dialog = payload,
  setMessage: (state, payload) => state.message = payload,
  setOutputType: (state, payload) => state.outputType = payload
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    axios
  }
}

export default module
