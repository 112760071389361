import { createApp } from 'vue'
import '@libs/computed-style'
import '@libs/scroll-to'
import { fireLoaded } from '@libs/on-loaded'
import { bindBaseComponents } from '@libs/bind-component'
import LineAddFriendButton from '@components/atoms/buttons/LineAddFriendButton.vue'
import SearchBar from '@components/organisms/search/SearchBar.vue'
import { store, key } from '@stores/index'

export const initializeApp = async () => {
  const app = createApp({
    mounted: () => {
      fireLoaded()
    }
  })

  await bindBaseComponents(app)
  app.component('line-add-friend-button', LineAddFriendButton)
  app.component('search-bar', SearchBar)
  app.use(store, key)
  return app
}
