import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import crud from '@stores/modules/crud'
import Repository from '@repositories/parts'

interface State {}

const state = (): State => ({})

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  fetch: async ({ commit, dispatch, state }, payload: Parts.Request.Fetch) => {
    await dispatch('crud/error/outputTypeDialog', null)
    await dispatch('crud/fetch', {
      method: Repository.fetch,
      payload
    })
  }
}

const mutations: MutationTree<State> = {}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    crud
  }
}

export default module
