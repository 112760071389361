import { GetterTree, ActionTree, MutationTree } from 'vuex'
import favorite from '@stores/modules/me/favorite'
import { State as RootState } from '@stores/index'

interface State {}

const state = (): State => ({})

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {}

const mutations: MutationTree<State> = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    favorite
  }
}
