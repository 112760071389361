const callbacks: (() => void)[] = []
export const onLoaded = (callback: () => void) => {
  document.addEventListener('DOMContentLoaded', () => {
    callbacks.push(callback)
  })
}

export const fireLoaded = () => {
  callbacks.forEach((c) => c())
}
