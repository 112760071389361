import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '@stores/index'
import crud from '@stores/modules/crud'
import FavoriteRepository from '@repositories/me/favorite/parts'

interface State {}

const state = (): State => ({})

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  fetch: async ({ commit, dispatch, getters }) => {
    await dispatch('crud/error/outputTypeLog')
    await dispatch('crud/fetch', {
      method: FavoriteRepository.fetch
    })
    if (getters['crud/collection'] === null) {
      commit('crud/setCollection', [])
    }
  },
  find: async ({ commit, dispatch }, id: number) => {
    await dispatch('crud/error/outputTypeLog')
    await dispatch('crud/find', {
      method: FavoriteRepository.find,
      payload: id
    })
  },
  update: async ({ dispatch, getters }, id: number) => {
    await dispatch('crud/error/outputTypeDialog')
    await dispatch('crud/update', {
      method: FavoriteRepository.update,
      payload: id
    })
    if (getters['crud/isSucceedUpdate']) {
      await dispatch('fetch')
    }
  },
}

const mutations: MutationTree<State> = {}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    crud
  }
}

export default module
