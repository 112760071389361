import { ModuleTree } from 'vuex'
import { State as RootState } from '@stores/index'
import cart from './cart'
import dialog from './dialog'
import parts from './parts'
import partsCategory from './parts-category'
import product from './product'
import me from './me'

const modules: ModuleTree<RootState> = {
  cart,
  dialog,
  parts,
  partsCategory,
  product,
  me
}

export default modules
