const ProcessStates = {
  Initial: 'INITIAL',
  Running: 'RUNNING',
  Pending: 'PENDING',
  Success: 'SUCCESS',
  Failure: 'FAILURE'
} as const

export type ProcessState = typeof ProcessStates[keyof typeof ProcessStates]
export default ProcessStates
