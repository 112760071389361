<script setup lang="ts">
import _ from 'lodash'
import { onMounted } from 'vue'

const uid = _.uniqueId()

const loadScript = (): void  => {
  const script = window.document.createElement('script')
  script.src = 'https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js'
  script.async = true
  script.defer = true
  const container = window.document.getElementsByClassName(`line-add-friend-button uid-${uid}`)
  container[0].appendChild(script)
}

onMounted(() => {
  loadScript()
})
</script>

<template lang="pug">
.line-add-friend-button(:class="`uid-${uid}`")
  .line-it-button(
    data-lang="ja"
    data-type="friend"
    data-env="REAL"
    data-lineId="@723tjpsl"
    style="display: none;"
  )
</template>
